.qr-border{
  height: 220px;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 12px;
}

.qr-border-2{
  height: 180px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 12px;
}

.places-container {
  position: absolute;
  top: 20%;
  right: 50%;
  transform: translateX(50%);
  z-index: 10;
  width: 300px;
}

.map-container {
  width: 100%;
  height: 40vh;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}